import { Button, Col, Drawer, notification, Row, Space, Spin } from 'antd';
import { UploadFile } from 'antd/lib';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { editLottie } from 'assets/lottieAssets';
import { colors, toRgba } from 'common/styles/colors';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ColorCard } from 'components/molecules/ColorCard';
import { PrintSlipList } from 'components/molecules/PrintSlipList';
import { ShipmentForm } from 'components/molecules/ShipmentForm';
import { FormikProvider, useFormik } from 'formik';
import { Shipment, ShipmentPayload, shipmentSchema } from 'models/DockReceipt';
import { QueryErrorModel } from 'models/ErrorModel';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateShipmentMutation } from 'redux/services/linda/lindaApi';
import { setPrintDetails } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import { LottieButton } from './LottieButton';
import { PreviewLabelButton } from './PreviewLabelButton';
import { ShipmentAttachmentUpload } from './ShipmentAttachmentUpload';

type Props = {
  record: Shipment;
  dockReceiptId: string;
  isCreate?: boolean;
  setShipment?: React.Dispatch<React.SetStateAction<Shipment | undefined>>;
};

export const EditShipmentsDrawer: FC<Props> = ({ record, dockReceiptId, isCreate = false, setShipment }) => {
  const { xs } = useBreakpoint();
  const { isShipmentCreated } = useAppSelector((state) => state.app);

  const [updateDockReceipt] = useUpdateShipmentMutation();
  const [fileList, setFileList] = useState<UploadFile[]>(
    record.attachments.map((attachment, index) => ({
      name: attachment.name,
      url: attachment.url,
      uid: String(index)
    }))
  );

  const [visible, setVisible] = useState(isCreate && record ? true : false);
  const dispatch = useDispatch();

  const formik = useFormik<ShipmentPayload>({
    enableReinitialize: true,
    validationSchema: shipmentSchema,
    initialValues: {
      status: record.status ?? '',
      receiptType: record.receiptType ?? '',
      processingWarehouse: record?.processingWarehouse ?? '',
      assignedToUserFullName: record?.assignedToUserFullName ?? '',
      assignedToUserId: record?.assignedToUserId ?? '',
      returnReference: record?.returnReference ?? '',
      shipmentDate: record?.shipmentDate ?? '',
      trackingNumbers: record.trackingNumbers ?? [],
      accountId: record?.accountId ?? '',
      mdsiOrderNumber: record?.mdsiOrderNumber ?? '',
      customerReferenceNumber: record?.customerReferenceNumber ?? '',
      customerOrderNumber: record?.customerOrderNumber ?? '',
      acuityDocumentId: record?.acuityDocumentId ?? '',
      attachmentsToCreate: [],
      attachmentsToDelete: [],
      note: '',
      accountNumber: record.accountNumber ?? ''
    },
    onSubmit: async (values) => {
      try {
        const resp = await updateDockReceipt({ dockReceiptId: dockReceiptId, shipmentId: record.id, payload: values }).unwrap();

        notification.success({ message: `Shipment ${resp.loadId} successfully updated` });
        setVisible(false);
        formik.resetForm();
      } catch (e) {
        console.error(e, 'error');

        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });
      }
    }
  });

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
    formik.resetForm();
    const newDetails = formik.values.trackingNumbers.reduce((acc, num) => {
      acc[num] = { qty: 1 };

      return acc;
    }, {} as { [key: string]: { qty: number } });

    if (setShipment) setShipment(undefined);

    dispatch(setPrintDetails(newDetails));
  };

  return (
    <FormikProvider value={formik}>
      {!isCreate && <LottieButton onClick={showDrawer} animationData={editLottie} borderColor="#FFBF66" hoverColor="#FFE9D9" tooltip={xs ? undefined : 'Edit'} />}
      <Drawer
        footer={
          <Row style={{ width: '100%', height: xs ? 50 : undefined }} justify={xs ? 'center' : 'end'}>
            <Space>
              <Button style={{ width: xs ? 100 : undefined }} onClick={onClose}>
                Cancel
              </Button>
              <Button style={{ width: xs ? 100 : undefined }} type={'primary'} onClick={formik.submitForm}>
                Save
              </Button>
              <PreviewLabelButton record={record} />
            </Space>
          </Row>
        }
        style={{ borderRadius: xs ? 15 : 0 }}
        closeIcon={null}
        onClose={onClose}
        height={'75%'}
        width={xs ? '100%' : '30%'}
        open={visible}
        placement={xs ? 'bottom' : 'right'}>
        <ColorCard color={toRgba(colors.orangeWeb, 0.4)} title={`Edit Shipment ${record.shipmentId}`}>
          <Space direction="vertical" size={20}>
            <Spin spinning={formik.isSubmitting} indicator={<LoaderWithMessage loadingMessage="Updating Dock Receipt..." />}>
              <ShipmentForm isCreate={false} recordId={dockReceiptId} />
              <Space direction="vertical" style={{ width: '100%', marginTop: 16 }}>
                <Row gutter={[8, 0]}>
                  <Col span={xs ? 24 : 24}>
                    <ShipmentAttachmentUpload record={record} fileListState={fileList} setFileList={setFileList} />
                  </Col>
                  {xs && <Col style={{ margin: 0, marginBottom: 8, marginTop: 8 }} />}
                </Row>
                <Row gutter={[8, 0]}>
                  <Col span={xs ? 24 : 24}>
                    <PrintSlipList />
                  </Col>
                  {xs && <Col style={{ margin: 0, marginBottom: 8, marginTop: 8 }} />}
                </Row>
              </Space>
            </Spin>
          </Space>
        </ColorCard>
      </Drawer>
    </FormikProvider>
  );
};
