import { Button, Row, Space, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { colors } from 'common/styles/colors';
import { Shipment } from 'models/DockReceipt';
import { FC, useRef } from 'react';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';
import { useAppSelector } from 'redux/store';

type Props = {
  record: Shipment;
};

export const PreviewLabelButton: FC<Props> = ({ record }) => {
  const printRef = useRef<HTMLDivElement>(null);
  const { xs } = useBreakpoint();
  const { printDetails } = useAppSelector((state) => state.app);

  const handlePrint = useReactToPrint({
    contentRef: printRef
  });

  return (
    <>
      <Button style={{ background: colors.success[600], color: 'white', width: xs ? 100 : undefined }} onClick={() => handlePrint()}>
        Print Labels
      </Button>
      <Row ref={printRef} className="print-container" style={{ display: 'none', padding: 5 }} align={'middle'}>
        {Object.entries(printDetails).map(([label, qty], index) => {
          return Array.from({ length: qty.qty }).map((_, i) => (
            <Space key={`${label}-${i}`} direction="vertical" align="center" size={0} className="label-container">
              <Typography.Title level={5} style={{ padding: 0, margin: 0, textAlign: 'center', marginTop: 5 }}>
                Container ID
              </Typography.Title>
              <Typography.Title level={5} style={{ padding: 0, margin: 0, textAlign: 'center' }}>
                {record.loadId}-{record.shipmentId}
              </Typography.Title>
              <Barcode displayValue={false} value={`${record.loadId}-${record.shipmentId}`} width={1.5} height={40} />
              <Row style={{ width: 250 }} justify={'space-between'}>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'center', fontSize: 10 }}>Tracking Number:</Typography.Text>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'end', fontSize: 10 }}>{label}</Typography.Text>
              </Row>
              <Row style={{ width: 250 }} justify={'space-between'}>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'start', fontSize: 10 }}>Dock Date:</Typography.Text>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'end', fontSize: 10 }}>{new Date(record.shipmentDate).toLocaleDateString()}</Typography.Text>
              </Row>
              <Row style={{ width: 250 }} justify={'space-between'}>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'start', fontSize: 10 }}>Print Date:</Typography.Text>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'end', fontSize: 10 }}>
                  {new Date().toLocaleDateString()} {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </Typography.Text>
              </Row>
              <Row style={{ width: 250 }} justify={'space-between'}>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'center', fontSize: 10 }}>Customer ID:</Typography.Text>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'end', fontSize: 10 }}>{record.accountId}</Typography.Text>
              </Row>
              <Row style={{ width: 250 }} justify={'space-between'}>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'center', fontSize: 10 }}>Reference Number:</Typography.Text>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'end', fontSize: 10 }}>{record.returnReference}</Typography.Text>
              </Row>
              <Row style={{ width: 250 }} justify={'space-between'}>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'center', fontSize: 10 }}>MDSi Order Number:</Typography.Text>
                <Typography.Text style={{ padding: 0, margin: 0, textAlign: 'end', fontSize: 10 }}>{record.mdsiOrderNumber}</Typography.Text>
              </Row>
              <Barcode displayValue={false} value={record.mdsiOrderNumber} width={1.5} height={40} />
            </Space>
          ));
        })}
      </Row>
    </>
  );
};
